/* Version 1.0 */

@import "bootstrap";
/* style mat-table */
.hide {
  display: none;
}

/* Global styles */
html, body {
  height: 100%;
}

body {
  margin: 0;
  padding-top: 97px;
  overflow-y: scroll;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Button Switch / Slider */
.switch {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 34px;
  margin-bottom: 0px;
}

.switch input {
  display: none;
}

#navbarDropdown.dropdown-toggle:after {
  display:none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #009c81; //#0099ff; /* Primary */
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(95px);
  -ms-transform: translateX(95px);
  transform: translateX(95px);
}

.on {
  display: none;
}

.on, .off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Input close / remove icon */
.input-close-icon input {
  min-width: 250px;
  padding-right: 35px;
}

.input-close-icon a.btn {
  position: absolute;
  right: 15px;
  top: 0;
}

@media only screen and (max-width: 575px) {
  .input-close-icon {
    min-width: 100%;
  }
}


/* Bootstrap overwrites */
.card {
  border-radius: 0px;
}

.btn-primary {
  color: #fff;
}


/* Form group - Fix jumping with form validation */
.form-group {
  margin-bottom: 20px;
}

.form-group .invalid-feedback {
  position: absolute;
  margin-top: 0px;
}

/* Custom center navbar */
.itop-navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
}

// Mobile fix
@media only screen and (max-width: 575px) {
  .show .itop-navbar-center {
    position: inherit;
    left: unset;
    transform: none;
    text-align: center;
  }
}


/* Mat table responsive */
@media screen and (max-width: 960px) {
  .mat-table {
    border: 0;
    vertical-align: middle
  }

  .mat-table caption {
    font-size: 1em;
  }

  /*  Enable this to hide header */
  .mat-table .mat-header-cell {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  mat-header-row {
    display: none !important;
  }


  .mat-table .mat-row {
    border-bottom: 5px solid #ddd;
    display: block;
  }
  /*
  .mat-table .mat-row:nth-child(even) {background: #CCC}
  .mat-table .mat-row:nth-child(odd) {background: #FFF}
  */
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    padding: 0 20px;
    line-height: 48px;
  }

  .mat-row::after {
    display: none;
  }

  .mat-table .mat-cell:before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-size: .85em;
  }

  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }
}


/* Calendar mobile style */
@media only screen and (max-width: 600px) {
  .fc .fc-toolbar {
    display: block !important;
  }

  .fc .fc-toolbar .fc-toolbar-chunk {
    margin-bottom: 10px;
  }

  .fc-view-harness.fc-view-harness-active {
    height: 61vh !important;
  }

  .fc .fc-timegrid-slot {
    height: 35px !important;
  }

}

@media only screen and (min-width: 600px) {
  .fc-view-harness.fc-view-harness-active {
    height: 78vh !important;
  }
}

.fc .fc-event-title {
  white-space: pre-line;
}


/* Container NBR style */
.container {
  background: white;
  padding-top: 1rem !important;
}

@media only screen and (min-width: 768px) {
  body.mat-typography {
    background-image: url(/assets/img/symbol-green-bg-500x550.png);
    background-color: #d0cac3;
    background-position: bottom right;
    background-repeat: no-repeat;
  }
}

/* Comment */

